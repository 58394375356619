import { template as template_a8681c7eaf0f4b9e98465c9f58f2b1f4 } from "@ember/template-compiler";
import UserLink from "discourse/components/user-link";
import avatar from "discourse/helpers/avatar";
import icon from "discourse-common/helpers/d-icon";
const ReviewableCreatedBy = template_a8681c7eaf0f4b9e98465c9f58f2b1f4(`
  <div class="created-by">
    {{#if @user}}
      <UserLink @user={{@user}}>{{avatar @user imageSize="large"}}</UserLink>
    {{else}}
      {{icon "trash-can" class="deleted-user-avatar"}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ReviewableCreatedBy;
