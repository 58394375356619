import { template as template_c5949c3db585405cbb75e26e3668794a } from "@ember/template-compiler";
const FKControlMenuContainer = template_c5949c3db585405cbb75e26e3668794a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
