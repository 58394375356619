import { template as template_4afcb92d2e6f4bc0b216559545373a35 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_4afcb92d2e6f4bc0b216559545373a35(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
